import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "antd";
import ButtonDefault from "../../components/ButtonDefault";
import Breadcrumb from "../../components/Breadcrumb";
import InputSelect from "../../components/InputSelect";
import { AuthServices } from "../../services/AuthServices";
import { useAppSelector } from "../../hooks";
import InputDefault from "../../components/InputDefault";
import SubmitError from "../../components/SubmitError";
import imgSend from "../../assets/images/img_send/img_send.svg";
import "./index.scss";
import { utils } from "../../utils/utils";

const Registrations = () => {
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [nextStep, setNextStep] = useState(true);
  const dataPage = useAppSelector((state) => state.pageData);
  const [form] = Form.useForm();
  const [furigana, setFurigana] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [year, setYear] = useState((new Date()).getFullYear());
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(1);
  const [gender, setGender] = useState(1);
  const checkDate = utils.dateIsValid(year, month, day);
  const checkName = async () => {
    const inputEnter = (form.getFieldValue("name") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("名前を入力してください。");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };
  const checkEmail = async () => {
    const inputEnter = (form.getFieldValue("email") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("メールアドレスを入力してください。");
    }
    const regex =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
    if (!regex.test(inputEnter) || !utils.validateEmail(inputEnter)) {
      return await Promise.reject("メールアドレスは不正な値です。");
    }

    return await Promise.resolve();
  };
  const checkFurigana = async () => {
    const inputEnter = (form.getFieldValue("furigana") || "").trim();
    const regex = /^[\u3040-\u309F\u3000 ]*$/g;
    if (!regex.test(inputEnter || inputEnter.length === 0)) {
      return await Promise.reject("ふりがなを入力してください。");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };
  const handleFinish = async () => {
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      user: {
        email: email.trim(),
        name: name.trim(),
        furigana: furigana.trim(),
        sex: gender,
        date_of_birth: `${year}/${month}/${day}`
      },
    };

    try {
      const res = await AuthServices.checkValidate(valuesSend);
      const name = (form.getFieldValue("name") || "").trim();
      const email = (form.getFieldValue("email") || "").trim();
      const furigana = (form.getFieldValue("furigana") || "").trim();
      form.setFieldValue("name", name);
      form.setFieldValue("furigana", furigana);
      form.setFieldValue("email", email);
      if (res.data.status == 200) {
        setNextStep(false);
        setIsError(false);
      } else {
        setIsError(true);
        setMessageError(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };

  return (
    <>
      <div className="breadcrumb-form-signup">
        {nextStep ? (
          <Breadcrumb>アカウントを作成</Breadcrumb>
        ) : (
          <Breadcrumb backButton={false}>アカウントを作成</Breadcrumb>
        )}
      </div>
      <div>
        <Form
          scrollToFirstError
          form={form}
          onFinish={(values) => {
            handleFinish();
          }}
          onFinishFailed={onFinishFailed}
          className="form-signup"
        >
          {nextStep ? (
            <Row gutter={20}>
              <Col span={24}>
                {isError ? (
                  <SubmitError
                    description={messageError}
                    type="error"
                    closable
                    style={{ whiteSpace: "break-spaces" }}
                  />
                ) : null}
                <Form.Item
                  label="名前"
                  name="name"
                  rules={[{ validator: checkName }]}
                >
                  <InputDefault
                    value={furigana}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="ふりがな"
                  name="furigana"
                  rules={[{ validator: checkFurigana }]}
                >
                  <InputDefault
                    value={name}
                    onChange={(e) => setFurigana(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="メールアドレス"
                  name="email"
                  rules={[{ validator: checkEmail }]}
                >
                  <InputDefault
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="性別"
                  name="sex"
                >
                  <Row className="character-sex">
                    <Col span={12}>
                      {gender === 1 ? (
                        <ButtonDefault
                          classButton="full-gender"
                          outline={false}
                          onClick={() => { }}
                          htmlType="button"
                        >
                          男性
                        </ButtonDefault>
                      ) : (
                        <ButtonDefault
                          classButton="outline-gender"
                          outline
                          onClick={() => { setGender(1) }}
                          htmlType="button"
                        >
                          男性
                        </ButtonDefault>
                      )}
                    </Col>
                    <Col span={12} className="button-right">
                      {gender === 2 ? (
                        <ButtonDefault
                          classButton="full-gender"
                          outline={false}
                          onClick={() => { }}
                          htmlType="button"
                        >
                          女性
                        </ButtonDefault>
                      ) : (
                        <ButtonDefault
                          classButton="outline-gender"
                          outline
                          onClick={() => { setGender(2) }}
                          htmlType="button"
                        >
                          女性
                        </ButtonDefault>
                      )}
                    </Col>
                  </Row>
                </Form.Item>

                <Form.Item
                  label="生年月日"
                  name="date_of_birth"
                >
                  <Row style={{ justifyContent: "space-between" }}>
                    <Col style={{ width: "130px" }}>
                      <InputSelect
                        checkDate={checkDate}
                        widthInput="105px"
                        onChange={(value) => setYear(value)}
                        options={utils.getUserYears()}
                        defaultValue={(new Date()).getFullYear()}
                      >
                        年
                      </InputSelect>
                    </Col>
                    <Col style={{ width: "95px" }}>
                      <InputSelect
                        checkDate={checkDate}
                        widthInput="70px"
                        onChange={(value) => setMonth(value)}
                        options={utils.getMonths()}
                        defaultValue={1}
                      >
                        月
                      </InputSelect>
                    </Col>
                    <Col style={{ width: "95px" }}>
                      <InputSelect
                        checkDate={checkDate}
                        widthInput="70px"
                        onChange={(value) => setDay(value)}
                        options={utils.getDays()}
                        defaultValue={1}
                      >
                        日
                      </InputSelect>
                    </Col>
                  </Row>
                  <Row>
                    <div className="age-label">
                      {`年齢: ${utils.calculateAge(year, month, day)}歳`}
                    </div>
                  </Row>
                </Form.Item>

                <Form.Item className="btn-sign-up">
                  <ButtonDefault
                    classButton="full-big"
                    outline={false}
                    htmlType="submit"
                  >
                    アカウントを作成する
                  </ButtonDefault>
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={20}>
              <Col span={24}>
                <div
                  className="form-registrantions"
                  style={{
                    textAlign: "center",
                    marginBottom: "max(1px, 100vh - 507px)",
                  }}
                >
                  <img src={imgSend} style={{ paddingTop: "37px" }} />
                  <h2
                    style={{
                      paddingTop: "20px",
                      fontFamily: "Noto_Sans_Bold",
                      fontWeight: "700",
                      fontSize: "20px",
                    }}
                  >
                    メールを送信しました
                  </h2>
                </div>
              </Col>
            </Row>
          )}
        </Form>
      </div>
    </>
  );
};

export default Registrations;
