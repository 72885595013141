import { isHoliday } from "@holiday-jp/holiday_jp";
import { designPackaging } from "../../configuration"
import { notification } from "antd";

type NotificationType = 'success' | 'info' | 'warning' | 'error';

export const utils = {
  calculateAge(year, month, day) {
    const today = new Date()
    const birthDate = new Date(year, month, day)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },
  getUserYears() {
    const TodayDate = new Date()
    let years = []
    for (let i = TodayDate.getFullYear(); i >= TodayDate.getFullYear() - 100; i--) {
      years.push({ value: `${i}`, label: `${i}` })
    }
    return years
  },
  getYears() {
    const TodayDate = new Date()
    let years = []
    for (let i = TodayDate.getFullYear() - 15; i <= TodayDate.getFullYear() + 10; i++) {
      years.push({ value: `${i}`, label: `${i}` })
    }
    return years
  },
  convertDataObjectToArray(obj: any) {
    return Object.keys(obj).map((key: any) => obj[key])
  },
  getDays() {
    let days = []
    for (let i = 1; i <= 31; i++) {
      days.push({ value: `${i}`, label: `${i}` })
    }
    return days
  },
  getMonths() {
    let months = []
    for (let i = 1; i <= 12; i++) {
      months.push({ value: `${i}`, label: `${i}` })
    }
    return months
  },
  getZeroMonths() {
    let months = []
    for (let i = 1; i <= 12; i++) {
      i < 10 ? months.push({ value: `0${i}`, label: `0${i}` }) : months.push({ value: `${i}`, label: `${i}` })
    }
    return months
  },
  dateIsValid(year, month, day) {
    month = month - 1;
    var d = new Date(year, month, day);
    if (d.getFullYear() == year && d.getMonth() == month && d.getDate() == day) {
      return true;
    }
    return false;
  },
  formatDayAndMonth(n: any) {
    if (n.length === 1) {
      return '0' + n
    }
    return n
  },
  formatMoneyNonDecimal(n: float, decimal: number) {
    if (n === null) {
      return 0
    }
    return n.toFixed(decimal).replace(/./g, function (c, i, a) {
      return i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c
    })
  },
  getCurrentYear() {
    const TodayDate = new Date();
    return TodayDate.getFullYear()
  },
  getStorage(key: string) {
    return localStorage.getItem(key)
  },
  setStorage(key: string, value: any) {
    return localStorage.setItem(key, value)
  },
  deleteStorage(key: string) {
    return localStorage.removeItem(key)
  },
  convertToObject(key: any) {
    if (key === undefined || key === null) {
      return JSON.parse('{}')
    } else
      return JSON.parse(key)
  },
  getTotalQuantity(items: any) {
    return items.reduce((previousValue, item) => (previousValue, item) => previousValue + item.giftPackaging.pricePackaging, 0)
  },
  renderGift(gift: string) {
    if (gift === designPackaging.designA.type) {
      return designPackaging.designA.title
    }
    if (gift === designPackaging.designB.type) {
      return designPackaging.designB.title
    }
    return
  },
  getLastCharacter(string: string, n: number) {
    return string.replace(/\s/g, '').slice(-n)
  },
  getYearsMethod() {
    const TodayDate = new Date()
    let years = []
    for (let i = TodayDate.getFullYear(); i <= TodayDate.getFullYear() + 50; i++) {
      years.push({ value: `${i}`, label: `${i}` })
    }
    return years
  },
  filterItems(data: any) {
    const uniqueArray = data.filter((value, index) => {
      const _value = JSON.stringify(value);
      return index === data.findIndex(obj => {
        return JSON.stringify(obj) === _value;
      });
    });
    return uniqueArray
  },
  countItemsSame(data: any, chooseGift, designPackaging = null, id: number) {
    if (chooseGift === true) {
      return data.filter(ele => ele.giftPackaging.chooseGift === chooseGift && ele.id === id).length
    } else {
      return data.filter(ele => ele.giftPackaging.typePackaging === designPackaging && ele.id === id).length
    }
  },

  compressOrderItems(items: any) {
    let results = []
    for (let i = 0; i < items.length; i++) {
      let currentItem = items[i];
      let compressed = false;
      for (let j = 0; j < results.length; j++) {
        if (currentItem?.id === results[j]?.id &&
          currentItem?.giftPackaging?.chooseGift === results[j]?.giftPackaging?.chooseGift &&
          currentItem?.giftPackaging?.typePackaging === results[j]?.giftPackaging?.typePackaging) {
          results[j].amount += 1;
          compressed = true;
        }
      }
      if (!compressed) results.push({
        id: currentItem.id,
        giftPackaging: currentItem.giftPackaging,
        book_id: currentItem.book_id,
        book_name: currentItem.book_name,
        image: currentItem.image,
        amount: 1,
        is_gift: currentItem.is_gift,
        name: currentItem.name,
        price: currentItem.price
      });
    }

    return results
  },
  compressOrderItemsHistory(items: any) {
    let results = []
    if (!items) {
      results = []
    } else {
      for (let i = 0; i < items.length; i++) {
        let currentItem = items[i];
        let compressed = false;
        for (let j = 0; j < results.length; j++) {
          if (
            currentItem?.order_item === results[j]?.order_item &&
            currentItem?.packaging === results[j]?.packaging &&
            currentItem?.type_packaging === results[j]?.type_packaging &&
            !currentItem?.is_gift
          ) {
            results[j].amount += 1;
            compressed = true;
          }
        }
        if (!compressed) results.push({
          order_item: currentItem.order_item,
          packaging: currentItem.packaging,
          type_packaging: currentItem.type_packaging,
          character_name: currentItem.character_name,
          book_name: currentItem.book_name,
          book_img: currentItem.book_img,
          amount: 1,
          gift_status: currentItem.gift_status,
          is_gift: currentItem.is_gift,
          book_price: currentItem.book_price
        });
      }
      return results
    }
  },
  compressOrdersInMypage(items: any) {
    let results = []
    for (let i = 0; i < items.length; i++) {
      let currentItem = items[i];
      let compressed = false;
      for (let j = 0; j < results.length; j++) {
        if (currentItem?.order_item_id === results[j]?.order_item_id &&
          currentItem?.packaging === results[j]?.packaging &&
          currentItem?.type_packaging === results[j]?.type_packaging &&
          !currentItem?.is_gift
        ) {
          results[j].amount += 1;
          compressed = true;
        }
      }
      if (!compressed) results.push({
        id: currentItem.id,
        order_item_id: currentItem.order_item_id,
        title: currentItem.title,
        is_gift: currentItem.is_gift,
        cover_image_name: currentItem.cover_image_name,
        cover_image_url: currentItem.cover_image_url,
        packaging: currentItem.packaging,
        amount: 1,
        type_packaging: currentItem.type_packaging,
      });
    }
    results = results.sort((itemA, itemB) => itemA.id - itemB.id)
    return results
  },
  deliveryFeeRender(n: any) {
    let number = n / 5
    if (number === +number && number !== (number | 0)) {
      return Number(Math.floor(number) + 1)
    } else {
      return number
    }
  },
  validateEmail(n: string) {
    const regexCharacterEmail = /^[-a-zA-Z0-9@_.+]+$/
    const characterSpecial = '_+-.'
    if (!regexCharacterEmail.test(n)) {
      return false
    }
    for (let i = 0; i < characterSpecial.length; i++) {
      if (n.includes(`${characterSpecial[i]}@`) || n.includes(`@${characterSpecial[i]}`)) {
        return false
      }
    }

    return true
  },
  openNotification(data: { type?: NotificationType, message?: any, description?: any, trigger?: string }) {
    notification.destroy()
    notification.open({
      type: `${data.type}`,
      message: `${data.message}`,
      description: `${data.description}`,
      duration: 3.5
    });
  },
  getMobileOS() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }
    return "Other"
  },
  actionScrollTo () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  },
  isCustomHoliday(date) {
    if (date.getMonth() === 11 && date.getDate() >= 29) return true;
    if (date.getMonth() === 0 && date.getDate() <= 5) return true;
    return false;
  },
  // 指定日から土日祝日を除いた、指定した日数が経過後の最短日を算出
  getBusinessDate(startDate: Date, daysToAdd: number){
    let count = 0;
    let currentDate = new Date(startDate);
    while (count < daysToAdd) {
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0 && !isHoliday(currentDate) && !this.isCustomHoliday(currentDate)) {
        count++;
      }
    }
    return currentDate;
  }
}
