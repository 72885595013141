import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import ButtonDefault from "../../../components/ButtonDefault";
import InputSelect from "../../../components/InputSelect";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { getDataPage } from "../../../redux/slices/pageDataSlice";
import { Button, Form, notification, Spin, Col, Row } from "antd";
import { AuthServices } from "../../../services/AuthServices";
import InputDefault from "../../../components/InputDefault";
import SubmitError from "../../../components/SubmitError";
import { utils } from "../../../utils/utils";

const UserInfo = (data) => {
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const [isError, setIsError] = useState(false);
  const [messageError, setMessageError] = useState("");
  const dataPage = useAppSelector((state) => state.pageData);
  const currentUser = JSON.parse(utils.getStorage("current_user"));
  const [gender, setGender] = useState(currentUser?.sex);
  const [year, setYear] = useState((new Date(currentUser?.date_of_birth)).getFullYear());
  const [month, setMonth] = useState((new Date(currentUser?.date_of_birth)).getMonth() + 1);
  const [day, setDay] = useState((new Date(currentUser?.date_of_birth)).getDate());
  const openNotificationWithIcon = (type, message) => {
    notification.destroy();
    notification.open({
      message: "更新しました。",
      duration: 3.5,
    });
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getDataPage(data));
  }, [dispatch]);
  const checkDate = utils.dateIsValid(year, month, day);

  const onFinish = async (values: any) => {
    const valuesSend = {
      authenticity_token: dataPage.data?.data?.form_authenticity_token,
      name: values.name.trim(),
      furigana: values.furigana.trim(),
      sex: values.sex,
      date_of_birth: `${year}/${month}/${day}`,
      update_form: "user_info",
    };
    setLoading(true);

    try {
      const res = await AuthServices.updateInfo(valuesSend);
      const name = (form.getFieldValue("name") || "").trim();
      const furigana = (form.getFieldValue("furigana") || "").trim();
      form.setFieldValue("name", name);
      form.setFieldValue("furigana", furigana);
      if (res.status == 204) {
        setLoading(false);
        setIsError(false);
        openNotificationWithIcon("success", res.data.message);
      } else {
        setIsError(true);
        setMessageError(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkName = async () => {
    const inputEnter = (form.getFieldValue("name") || "").trim();
    if (inputEnter.length === 0) {
      return await Promise.reject("名前を入力してください。");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }
  };

  const checkFurigana = async () => {
    const inputEnter = (form.getFieldValue("furigana") || "").trim();
    const regex = /^[\u3040-\u309F\u3000 ]*$/g;
    if (!regex.test(inputEnter || inputEnter.length === 0)) {
      return await Promise.reject("ふりがなを入力してください。");
    }
    if (inputEnter.length > 255) {
      return await Promise.reject("最大は255文字になります。");
    }

    return await Promise.resolve();
  };

  const onFinishFailed = (errorInfo: any) => {
    setIsError(true);
    setMessageError(
      "正しく入力されていない項目があります。\nメッセージをご確認の上、もう一度ご入力ください。"
    );
  };

  return (
    <Spin spinning={loading} wrapperClassName='profile-spin'>
      <div className="breadcrumb_info">
        <Breadcrumb backButton={true}>ユーザー情報</Breadcrumb>
      </div>
      <div className="form_info user-info">
        <Form
          name="basic"
          form={form}
          initialValues={{
            name: currentUser?.name,
            furigana: currentUser?.furigana,
            sex: currentUser?.sex,
            date_of_birth: currentUser?.date_of_birth
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {isError ? (
            <SubmitError
              description={messageError}
              type="error"
              closable
              style={{ whiteSpace: "break-spaces" }}
            />
          ) : null}
          <Form.Item
            label="名前"
            name="name"
            className="label_form_update"
            rules={[{ validator: checkName }]}
          >
            <InputDefault className="input_update" />
          </Form.Item>

          <Form.Item
            label="ふりがな"
            name="furigana"
            className="label_form_update"
            rules={[{ validator: checkFurigana }]}
          >
            <InputDefault />
          </Form.Item>

          <Form.Item
            label="性別"
            name="sex"
            className="label_form_update"
          >
            <Row className="character-sex">
              <Col span={12}>
                {gender === 1 ? (
                  <ButtonDefault
                    classButton="full-gender"
                    outline={false}
                    onClick={() => { }}
                    htmlType="button"
                  >
                    男性
                  </ButtonDefault>
                ) : (
                  <ButtonDefault
                    classButton="outline-gender"
                    outline
                    onClick={() => {
                      form.setFieldValue("sex", 1);
                      setGender(1)
                    }}
                    htmlType="button"
                  >
                    男性
                  </ButtonDefault>
                )}
              </Col>
              <Col span={12} className="button-right">
                {gender === 2 ? (
                  <ButtonDefault
                    classButton="full-gender"
                    outline={false}
                    onClick={() => { }}
                    htmlType="button"
                  >
                    女性
                  </ButtonDefault>
                ) : (
                  <ButtonDefault
                    classButton="outline-gender"
                    outline
                    onClick={() => {
                      form.setFieldValue("sex", 2);
                      setGender(2);
                    }}
                    htmlType="button"
                  >
                    女性
                  </ButtonDefault>
                )}
              </Col>
            </Row>
          </Form.Item>

          <Form.Item
            label="生年月日"
            name="date_of_birth"
            className="label_form_update"
          >
            <Row style={{ justifyContent: "space-between" }}>
              <Col style={{ width: "130px" }}>
                <InputSelect
                  checkDate={checkDate}
                  widthInput="105px"
                  onChange={(value) => setYear(value)}
                  options={utils.getUserYears()}
                  defaultValue={year}
                >
                  年
                </InputSelect>
              </Col>
              <Col style={{ width: "95px" }}>
                <InputSelect
                  checkDate={checkDate}
                  widthInput="70px"
                  onChange={(value) => setMonth(value)}
                  options={utils.getMonths()}
                  defaultValue={month}
                >
                  月
                </InputSelect>
              </Col>
              <Col style={{ width: "95px" }}>
                <InputSelect
                  checkDate={checkDate}
                  widthInput="70px"
                  onChange={(value) => setDay(value)}
                  options={utils.getDays()}
                  defaultValue={day}
                >
                  日
                </InputSelect>
              </Col>
            </Row>
            <Row>
              <div className="age-label">
                {`年齢: ${utils.calculateAge(year, month, day)}歳`}
              </div>
            </Row>

          </Form.Item>

          <Form.Item>
            <div className="btn_update">
              <Button type="primary" htmlType="submit">
                変更する
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Spin>
  );
};

export default UserInfo;
